<nav class="header-navbar navbar navbar-expand-xl navbar-dark navbar-light bg-faded" (click)="focusOutFunction()">
  <a class="navbar-brand desktop-logo" routerLink="/">
    <img src="assets/images/logo.svg" class="logo-image" style="direction:rtl ;font-family:Roboto">
  </a>
  <a class="navbar-brand mobile-logo" routerLink="/">
    <img src="assets/images/mobile-logo.svg" class="logo-image" style="direction:rtl ;font-family:Roboto">
  </a>
 
  <div class="col-lg-6 col-md-6 col-12 mx-auto deals-search-on-mobile" *ngIf="search.hideOnSearch">
    <!-- <div class="input-group-prepend">
      <span class="input-group-text">
        <fa-icon [icon]="faSearch"></fa-icon>
      </span>
    </div> -->
    <input google-place type="text" class="form-control deals-location-search" placeholder="Search for location"
      (onSelect)="handleAddressChange($event)"  [(ngModel)]="searchedAddress" (keyup)="hideNearByDropDown()"
      (focus)="nearByDropDown = true" autofocus/>
    <!-- <input google-place type="text" class="form-control" placeholder="Search for location"
    (onSelect)="handleAddressChange($event)" [(ngModel)]="searchedAddress"
    (keyup.enter)="enterPressedOnSearch()" (focus)="nearByDropDown = true"
    (keyup)="hideNearByDropDown()" autofocus /> -->
    <fa-icon [icon]="faTimes" class="orange-font cross-icon" *ngIf="crossMark" (click)="crossMarkClick()">
    </fa-icon>
    <fa-icon [icon]="faSearch" id="mapSearch" class="orange-font search-icon" (click)="enableSearch()"></fa-icon>
    <div (click)="searchNearBy($event)" class="nearByDropDownText" *ngIf="nearByDropDown">
      Near By
    </div>
  </div>
 
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#dealsPageNavBar" aria-controls="dealsPageNavBar" aria-expanded="false" aria-label="Toggle navigation">
    <fa-icon [icon]="faFilter" *ngIf="search.hideOnSearch"></fa-icon>
  </button>
  <ul class="mr-auto"></ul>
  <div [ngbCollapse]="!isNavbarCollapsed" class="collapse navbar-collapse pull-right" id="dealsPageNavBar"
    *ngIf="search.hideOnSearch">
    <div class="form-check-inline">
      <input type="checkbox" id="cartCheck" name="cart" [(ngModel)]="cartChecked" (change)="cartShopCheckBoxtrigger()">
      <label for="cartCheck">
        <img src="./assets/images/Cart_Map_Pin.svg" class="filterPinImg">
        <span>CART</span>
      </label>
    </div>
    <div class="form-check-inline">
      <input type="checkbox" id="shopCheck" name="shop" [(ngModel)]="shopChecked" (change)="cartShopCheckBoxtrigger()">
      <label for="shopCheck">
        <img src="./assets/images/SHOP_Map_Pin.svg" class="filterPinImg">
        <span>SHOP</span>
      </label>
    </div>

    <div class="col-lg-4 col-md-4 col-8 mx-auto search-bar" (click)="$event.stopPropagation()">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text">
          <fa-icon [icon]="faSearch"></fa-icon>
        </span>
      </div>
      <input google-place type="text" class="form-control deals-location-search" placeholder="Search for location"
        (onSelect)="handleAddressChange($event)" [(ngModel)]="searchedAddress" autofocus> -->
      <input google-place type="text" class="form-control deals-location-search" placeholder="Search for location"
        (onSelect)="handleAddressChange($event)" [(ngModel)]="searchedAddress" autofocus (keyup)="hideNearByDropDown()" 
        (focus)="nearByDropDown = true"/>
      <!-- <input google-place type="text" class="form-control" placeholder="Search for location"
      (onSelect)="handleAddressChange($event)" [(ngModel)]="searchedAddress"
      (keyup.enter)="enterPressedOnSearch()" (focus)="nearByDropDown = true"
      (keyup)="hideNearByDropDown()" autofocus /> -->
      <fa-icon [icon]="faTimes" class="orange-font cross-icon" *ngIf="crossMark" (click)="crossMarkClick()">
      </fa-icon>
      <fa-icon  [icon]="faSearch" id="mapSearch" class="orange-font search-icon" (click)="enableSearch()"></fa-icon>
      <div (click)="searchNearBy($event)" class="nearByDropDownText" *ngIf="nearByDropDown">
        Near By
      </div>
    </div>
   
    <div ngbDropdown class="d-inline-block categoryFilter">
      <button class="btn flat" id="categoryDropdown" ngbDropdownToggle>
        {{categoryMetaData.name}}
        <fa-icon class="float-right" [icon]="faAngleDown"></fa-icon>
      </button>
      <div ngbDropdownMenu aria-labelledby="categoryDropdown">
        <button class="dropdown-item" *ngFor="let category of categorylist" (click)="selectCategory(category)">
          <img [src]="category.iconUrl" class="dropdown-image" />
          {{category.label}}
        </button>
      </div>
    </div>
    <div class="commodity-Filter">
      <multi-selector-dropdown [list]="commodityList" [metaData]="commodityMetaData" *ngIf="dispalyCommodityFilter"
        (shareCheckedList)="shareCheckedList($event)" (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
      </multi-selector-dropdown>
    </div>
  </div>
  <div class="navbar-container">
    <!-- <div ngbDropdown class="d-inline-block categoryFilter" *ngIf="!showLogin">
      <button class="btn flat" id="categoryDropdown" ngbDropdownToggle>
        Hello user
        <fa-icon class="float-right" [icon]="faAngleDown"></fa-icon>
      </button>
      <div ngbDropdownMenu>
        <button class="dropdown-item" (click)="profile()">
          Profile
        </button>
        <button class="dropdown-item" (click)="profile()">
          Your reviews
        </button>
        <button class="dropdown-item" (click)="profile()">
          Your likes
        </button>
      </div>
    </div> -->
    <a routerLink="/login" class="btn btn-link" style="color: #000000;" *ngIf="showLogin">
      <fa-icon [icon]="faSignInAlt"></fa-icon> Login
    </a>
    <a (click)="logout()" class="btn btn-link" style="color: #000000;" *ngIf="!showLogin">
      <fa-icon [icon]="faSignInAlt"></fa-icon> Logout
    </a>
  </div>
</nav>
