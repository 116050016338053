import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { DealsService, LoaderService } from '../service';
import { prefix, faLongArrowAltLeft, faShareSquare, faDirections, faHeart } from '@fortawesome/free-solid-svg-icons';
import { NgbCarousel, NgbModal, NgbModalConfig, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../service/google-analytics.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../service'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var google: any;

@Component({
  selector: 'app-display-deals',
  templateUrl: './display-deals.component.html',
  styleUrls: ['./display-deals.component.scss']
})

export class DisplayDealsComponent implements OnInit, OnDestroy {
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  page ={
    loading:false
  }

  submitted = false;
  prefix = prefix;
  faLongArrowAltLeft = faLongArrowAltLeft;
  faShareSquare = faShareSquare;
  faDirections = faDirections;
  faHeart = faHeart;
  selected = 0;
  hovered = 0;
  like = true;
  sharedId = '';
  disLike = false;
  readonly = true;
  modalReadonly = false;
  selectedDeal;

  reviewRatingForm: FormGroup;
  isXtraLarge: boolean = true;
  innerwidth: number = window.innerWidth;
  filtersOnDisplay = {
    status: 'STARTED'
  };

  // google maps zoom level
  map: any;
  zoom: number = 18;
  lat: number;
  lng: number;
  markers: marker[] = []
  dealsListForLocation = [];
  direction: boolean = false;

  //api responses variable
  feedBack = {
    id: 0,
    activity: "",
    commentDescription: "",
    commentTitle: "",
    like: true,
    rating: 0
  };

  // courosel Params Starts
  showNavigationArrows = false;
  showNavigationIndicators = false;
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  interval = 5000;
  // courosel Params Ends
  public origin: any;
  public destination: any;
  private subscription: Subscription;
  //private filterStatus:Subscription;

  constructor(
    config: NgbModalConfig,
    private dealService: DealsService,
    private loaderService: LoaderService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    
    private userService: UserService,
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  
  }

  // Initialize category list for Filter
  // GET Current Location and load map
  // GET deals under this Location set Markers 
  ngOnInit(): void {
    
    this.userService.CheckUserLogin();
    if (this.innerwidth <= 1200) {
      this.isXtraLarge = false;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.origin = { lat: this.lat, lng: this.lng };
          this.markers.push({
            lat: this.lat,
            lng: this.lng,
            draggable: true,
          });
        }
      });
    }

    this.subscription = this.dealService.getDealsFilters.subscribe(dealsFilter => {
      this.filtersOnDisplay.status = this.dealService.dealsFilter.status;
      if (Object.keys(dealsFilter).length !== 0) {
       // this.loaderService.display(true);
        this.getDealsForLatLng(this.dealService.dealsFilter)
      }
    });
    // this.filterStatus = this.dealService.getFilterStatus.subscribe(status => {
    //   if(status.length > 0) {
    //     this.statusFilter('STARTED');
    //   }
    // })
  };

  // corousel Events Start
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  // corousel Events Ends

  /**
    * Method: GET Deals For deals Filters
    */
  getDealsForLatLng(filter: any) {
    // this.backToDealList();
     this.page.loading = true;
    // this.loaderService.display(true)
    this.lat = filter.lat;
    this.lng = filter.lng;

    this.dealService.getDeals(filter)
      .subscribe(
        deals => {
          if (deals.length) {
            this.page.loading = false;
           // this.loaderService.display(false)
            this.dealsListForLocation = deals;
            this.markers = deals;
            var id = this.dealService.getSharedId()
            if (id !== null && id !== undefined) {
              this.dealService.getSingleDeal(filter, id).subscribe(data => {
                this.selectedDeal = data[0]
              })
              id = null
              this.dealService.setSharedId(id);
            }
          }else{
            this.page.loading = false;
           // this.loaderService.display(false)
            this.dealsListForLocation = deals;
          }
        },  error => {
         // this.alertService.error(error.status_message);
         // this.loading = false;
        });

  }

  openedWindow: number = 0; // alternative: array of numbers

  openWindow(id) {
    this.openedWindow = id; // alternative: push to array of numbers
  }

  isInfoWindowOpen(id) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  onMouseOver(infoWindow, gm) {

    if (gm.lastOpen && gm.lastOpen.isOpen) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: false
    });
  }

  markerDragEnd(marker: marker, $event: MouseEvent) {
    console.log('dragEnd', marker, $event);
  }

  showUpDetails(deal: any) {
    if (deal) {
      // this.commonService.getDistance()
     
      const sessionAuth = JSON.parse(sessionStorage.getItem("auth"));
      this.selectedDeal = deal;
      if (sessionAuth === null) {
        this.sharedId = deal.id;
        this.like = false
        this.disLike = true
      } else {
        this.dealService.getFeedBack(deal.id).subscribe(data => {
          if (data.like !== null) {
            this.feedBack = data
            this.like = data.like
            this.disLike = !data.like
          } else {
            this.feedBack = data
            this.like = false
            this.disLike = true
          }
        });
      }


      this.googleAnalyticsService.eventEmitter("dealsDetailsPage", "dealDetails", "cart", "click", 10);
       this.triggerViewDeal(deal);
    }
  }

  triggerViewDeal(deal: any) {
    this.dealService.creatViewDeal(deal.id, 'VIEW')
      .subscribe(data => {
        console.log(data);
      });
  }

  mapReady(map: any) {
    this.map = map;

    var that = this;
    this.map.addListener("dragend", function () {
      map.setCenter(this.center); // Set map center to marker position
      let latitude = this.center.lat();
      let longitude = this.center.lng();
      var center = new google.maps.LatLng(latitude, longitude);
      that.dealService.dealsFilter.lat = latitude;
      that.dealService.dealsFilter.lng = longitude;
      var start = this.getBounds().getNorthEast();
      if (!!center && !!start && google && google.maps && google.maps.geometry) {
        let distStart = google.maps.geometry.spherical.computeDistanceBetween(center, start);
        that.dealService.dealsFilter.distance = distStart;
        that.dealService.updateDealsFilter(that.dealService.dealsFilter);
      }
    });
    this.map.addListener("zoom_changed", function () {
      var center = new google.maps.LatLng(that.lat, that.lng);
      var start = this.getBounds().getNorthEast();
      if (!!center && !!start && google && google.maps && google.maps.geometry) {
        let distStart = google.maps.geometry.spherical.computeDistanceBetween(center, start);
        that.dealService.dealsFilter.distance = distStart;
        that.dealService.updateDealsFilter(that.dealService.dealsFilter);
      }
    });
  }

  showDirection(deal: any) {
    this.direction = true;
    this.destination = {
      lat: deal.lat,
      lng: deal.lng
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.origin = { lat: this.lat, lng: this.lng };
        }
      });
      let link = "" + "http://maps.google.com/maps?saddr=" + this.origin.lat + "," + this.origin.lng + " &daddr=" + this.destination.lat + "," + this.destination.lng;
      window.open(link, "_blank");
    }
  }

  getTheCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          return position
        }
      });
    }
  }

  open(content, selectedDeal) {
    const sessionAuth = JSON.parse(sessionStorage.getItem("auth"));
    if (sessionAuth === null) {
      this.dealService.setSharedId(this.sharedId);
      this.router.navigate(['/login']);
    } else {
      this.reviewRatingForm = this.formBuilder.group({
        title: ['', Validators.required],
        commentDescription: ['', Validators.required]
      });


      this.modalService.open(content);
      const cloneRatingReview = JSON.parse(JSON.stringify(this.feedBack))
      this.reviewRatingForm.patchValue(cloneRatingReview);

    }
  }

  postButton(selectedDeal, feedBack) {
    this.modalService.dismissAll();
    let review = this.reviewRatingForm.getRawValue();
    feedBack.commentDescription = review.commentDescription
    //feature.project = this.appService.getProjectSelected().id;
    this.dealService.reviewRatingPost(selectedDeal.id, feedBack, "RATING").subscribe((data) => {
    });
  }

  get reviewRating() {
    return this.reviewRatingForm.controls;
  }

  reviewSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.reviewRatingForm.invalid) {
      return;
    }
  }

  backToDealList(dealId) {
    this.selectedDeal = null;
    this.direction = false;
    setTimeout(() => {
      var el = document.getElementById(dealId);
      el.scrollIntoView();
    }, 500);
  }

  triggerLike(selectedDeal, feedBack) {
    if (feedBack.like === null) {
      this.like = false;
      this.disLike = true;
      this.Activity(selectedDeal, feedBack, this.like)
    } else {
      this.like = !feedBack.like;
      this.disLike = feedBack.like;
      this.Activity(selectedDeal, feedBack, this.like)
    }
  }

  triggerDisLike(selectedDeal, feedBack) {
    if (feedBack.like === null) {
      this.like = false;
      this.disLike = true;
      this.Activity(selectedDeal, feedBack, this.disLike)
    } else {
      this.disLike = feedBack.like;
      this.like = !feedBack.like;
      this.Activity(selectedDeal, feedBack, this.like)
    }
  }

  Activity(selectedDeal, feedBack, likeAndDisLike) {
    const sessionAuth = JSON.parse(sessionStorage.getItem("auth"));
    if (sessionAuth === null) {
      this.dealService.setSharedId(this.sharedId);
      this.router.navigate(['/login']);
    } else {
      feedBack.like = likeAndDisLike
      this.dealService.reviewRatingPost(selectedDeal.id, feedBack, "LIKE").subscribe((data) => {
        this.showUpDetails(selectedDeal)
      });
    }
  }

  statusFilter(status: string) {
    if (status === "ENDED") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      this.dealService.dealsFilter.startTime = date.setHours(0, 0, 0, 0);
      this.dealService.dealsFilter.endTime = date.setHours(24, 0, 0, 0);
    } else {
      this.dealService.dealsFilter.startTime = null;
      this.dealService.dealsFilter.endTime = null;
    }
    this.dealService.dealsFilter.status = status;
    this.dealService.updateDealsFilter(this.dealService.dealsFilter);
  }

  ngOnDestroy() {
    //this.dealService.resetDealsFilter()
    //this.filterStatus.unsubscribe();
    this.subscription.unsubscribe();
  }
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  url?: object;
  draggable: boolean;
  title?: string;
  categoryName?: string;
  pricePerUnit?: string;
  remainingTime?: string;
  currencySymbol?: CharacterData;
  id?: number;
}
