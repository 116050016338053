<div class="container">
  <div class="row">
    <div class="col-sm-6 offset-sm-3">
      <h2>Register</h2>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col">
              <label for="firstName">First Name</label>
              <input type="text" formControlName="firstName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && registration.firstName.errors }" />
              <div *ngIf="submitted && registration.firstName.errors" class="invalid-feedback">
                <div *ngIf="registration.firstName.errors.required">First Name is required</div>
              </div>
            </div>
            <div class="col">
              <label for="lastName">Last Name</label>
              <input type="text" formControlName="lastName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && registration.lastName.errors }" />
              <div *ngIf="submitted && registration.lastName.errors" class="invalid-feedback">
                <div *ngIf="registration.lastName.errors.required">Last Name is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registration.email.errors }" />
          <div *ngIf="submitted && registration.email.errors" class="invalid-feedback">
            <div *ngIf="registration.email.errors.required">Email is required</div>
            <div *ngIf="registration.email.errors.email">Email Formate is Invalid</div>
          </div>
        </div>
        <div class="form-group">
          <label for="phone">Phone No</label>
          <input type="text" formControlName="phone" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registration.phone.errors }" />
          <div *ngIf="submitted && registration.phone.errors" class="invalid-feedback">
            <div *ngIf="registration.phone.errors.required">Phone Number is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registration.password.errors }" />
          <div *ngIf="submitted && registration.password.errors" class="invalid-feedback">
            <div *ngIf="registration.password.errors.required">Password is required</div>
            <div *ngIf="registration.password.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>
        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Register
          </button>
          <a routerLink="/login" class="btn btn-link">Cancel</a>
        </div>
      </form>
    </div>
  </div>
</div>
