import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,NavigationEnd  } from '@angular/router';
import { UserService } from '../service';
import { filter, pairwise } from 'rxjs/operators';
import { DealsService} from '../service/deals.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dealService: DealsService,
    private userService: UserService
  ) {
    // this.currentUrl = this.router.url;
    // router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {        
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = event.url;
    //     console.log(this.previousUrl)
    //     console.log(this.currentUrl)
    //   };
    // });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userEmail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // convenience getter for easy access to form fields
  get loginFormGetter() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.login(this.loginFormGetter.userEmail.value, this.loginFormGetter.password.value)
      .subscribe(
        data => {
          if(data !== null){
           // this.dealService.updateUserData(data)
            this.userService.updateUserData(data);
            let id = this.dealService.getSharedId()
            if(id !== null && id !== undefined){
              this.router.navigate(['/deals']);
            }else{
              this.router.navigate(['']);
            }
            
          }
          
       },
        error => {
          this.error = error.status_message;
          this.loading = false;
        });
  }

}
